import React, { useEffect, useState } from 'react';
import './home.css';


const Home = () => {

    return (
        <div className='bgx' style={{ height: '100vh', width: '100vw', margin: 0, padding: 0 }}>
            <iframe
                src="https://trustreel.carrd.co/#"
                style={{ border: 'none', height: '100%', width: '100%' }}
                title="TrustReel"
            />
        </div>
    );
};

export default Home;