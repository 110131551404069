import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const CreateVideoGenerationQueue = ({ replicaCode }) => {
    const [texts, setTexts] = useState(['']);
    const [videoName, setVideoName] = useState('');
    const [baseUrl, setBaseUrl] = useState('');
    const [customeUrl, setCustomeUrl] = useState('');
    const [isPlatinum, setIsPlatinum] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorCustomeUrl, setErrorCustomeUrl] = useState(null);

    const handleInputChange = (index, event, field) => {
        const newTexts = [...texts];
        newTexts[index] = {
            ...newTexts[index],
            [field]: event.target.value
        };
        setTexts(newTexts);
        console.log(newTexts);
    };

    const handleAddText = () => {
        setTexts([...texts, '']);
    };

    const handleRemoveText = (index) => {
        const newTexts = texts.filter((_, i) => i !== index);
        setTexts(newTexts);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token'); // Obtener el token JWT del almacenamiento local
        setLoading(true);

        const selectedLanguageId = parseInt(selectedLanguage, 10);

        try {
            // Hacer una solicitud fetch para verificar la customeURL
            const checkUrlResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/videos/check-custome-url`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Incluir el token JWT en los encabezados
                },
                body: JSON.stringify({ customeURL: customeUrl }),
            });

            if (checkUrlResponse.status === 400) {
                const errorData = await checkUrlResponse.json();
                setErrorCustomeUrl(errorData.error);
                setLoading(false);
                return; // Detener el envío del formulario
            }

            const res = await axios.post(
                `${backendUrl}/videos/api/video-generation-queue/`,
                {   
                    replicaCode: replicaCode, 
                    texts: texts,
                    videoName: videoName,
                    urlBase: baseUrl,
                    customeURL: customeUrl,
                    platinum: isPlatinum,
                    language: selectedLanguageId,
                    personalizedGreeting: personalizedGreeting,
                    personalizedCompany: companyWithType,
                    personalizedPosition: positionWithType,
                    personalizedThankYou: personalizedThankYou,
                    personalizedUrl: personalizedUrl,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Incluir el token JWT en los encabezados
                    },
                }
            );
            setResponse(res.data);
            setError(null);
        } catch (err) {
            setError(err.response ? err.response.data : 'Error');
            setResponse(null);
        }
        finally {
            setLoading(false);
        }
    };

    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const fetchLanguages = async () => {
        try {
            const response = await fetch(`${backendUrl}/videos/languages/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch languages');
            }

            const data = await response.json();
            setLanguages(data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    useEffect(() => {
        fetchLanguages();
    }, []);

    // useEffect(() => {
    //     console.log(languages);
    // }, [languages]);

    const [personalizedGreeting, setPersonalizedGreeting] = useState(null);
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [personalizedThankYou, setPersonalizedThankYou] = useState(null);
    const [personalizedUrl, setPersonalizedUrl] = useState(null);
    const [companyInputType, setCompanyInputType] = useState('Input');
    const [positionInputType, setPositionInputType] = useState('Input');
    const [companyWithType, setCompanyWithType] = useState(null);
    const [positionWithType, setPositionWithType] = useState(null);

    useEffect(() => {
        if (company) {
            setCompanyWithType(`${company}-${companyInputType.toLowerCase()}`);
        }
    }, [company, companyInputType]);

    useEffect(() => {
        if (position) {
            setPositionWithType(`${position}-${positionInputType.toLowerCase()}`);
        }
    }, [position, positionInputType]);

    useEffect(() => {
        console.log(companyWithType);
    }, [companyWithType]);

    useEffect(() => {
        console.log(positionWithType);
    }, [positionWithType]);

    return (
        <div className="p-6 bg-white shadow-md rounded-lg">
            <form onSubmit={handleSubmit} className="flex justify-center flex-col">
                <label>Language</label>
                <select
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    className="block w-full p-2.5 bg-white border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 mb-3"
                >
                    <option value="" disabled>Select a language</option>
                    {languages.map((language) => (
                        <option key={language.id} value={language.id}>{language.name}</option>
                    ))}
                </select>
                <label>Base URL</label>
                <input
                    type="text"
                    value={baseUrl}
                    onChange={(event) => setBaseUrl(event.target.value.toLowerCase().replace(/\s+/g, '-'))}
                    className="border border-gray-300 p-2 rounded-lg w-full mb-3"
                    placeholder="trustreel.ai/base-url/your-custom-url"
                    required
                />
                <label>Custom URL</label>
                <input
                    type="text"
                    value={customeUrl}
                    onChange={(event) => setCustomeUrl(event.target.value.toLowerCase().replace(/\s+/g, '-'))}
                    className="border border-gray-300 p-2 rounded-lg w-full mb-3"
                    placeholder="your-custom-url"
                    required
                />
                {errorCustomeUrl && (
                    <p className="text-red-500 text-sm mb-2">{errorCustomeUrl}</p>
                )}
                <input
                    type="text"
                    value={videoName}
                    onChange={(event) => setVideoName(event.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full mb-3"
                    placeholder={`Video Name`}
                    required
                />
                <label>Questions</label>
                {texts.map((text, index) => (
                    <div key={index} className="mb-4">
                        <input
                            type="text"
                            value={text.question}
                            onChange={(event) => handleInputChange(index, event, 'question')}
                            className="border border-gray-300 p-2 rounded-lg w-full"
                            placeholder={`Question ${index + 1}`}
                            required
                        />
                        <input
                            type="text"
                            value={text.keyQuestion}
                            onChange={(event) => handleInputChange(index, event, 'keyQuestion')}
                            className="border border-gray-300 p-2 rounded-lg w-full mt-2"
                            placeholder={`Key Question ${index + 1}`}
                            required 
                        />
                        <button
                            type="button"
                            onClick={() => handleRemoveText(index)}
                            className="mt-1 text-red-500"
                        >
                            Remove
                        </button>
                    </div>
                ))}

                <button
                    type="button"
                    onClick={handleAddText}
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg mb-4"
                >
                    Add Question
                </button>


                <label>Personalized Greeting</label>
                <textarea
                    value={personalizedGreeting}
                    onChange={(event) => setPersonalizedGreeting(event.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full mb-3"
                    placeholder={`Personalized Greeting`}
                />
                <label>Personalized Thank You</label>
                <textarea
                    value={personalizedThankYou}
                    onChange={(event) => setPersonalizedThankYou(event.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full mb-3"
                    placeholder={`Personalized Thank You`}
                />
                <label>Custom URL to redirect at the end</label>
                <input
                    value={personalizedUrl}
                    onChange={(event) => setPersonalizedUrl(event.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full mb-3"
                    placeholder={`Personalized URL`}
                />
                <label>Editable form fields</label>
                <text className='text-slate-600'>Replace form fields or give them a default value</text>
                <div className="flex space-x-4 mb-3">
                    <input
                        type="text"
                        value={company}
                        onChange={(event) => setCompany(event.target.value)}
                        className="border border-gray-300 p-2 rounded-lg w-1/2"
                        placeholder={`Company`}
                    />
                    <select
                        value={companyInputType}
                        onChange={(e) => setCompanyInputType(e.target.value)}
                        className="border border-gray-300 p-2 rounded-lg w-1/2"
                    >
                        <option value="Input">Input</option>
                        <option value="Value">Value</option>
                    </select>
                </div>

                <div className="flex space-x-4 mb-3">
                    <input
                        type="text"
                        value={position}
                        onChange={(event) => setPosition(event.target.value)}
                        className="border border-gray-300 p-2 rounded-lg w-1/2"
                        placeholder={`Position`}
                    />
                    <select
                        value={positionInputType}
                        onChange={(e) => setPositionInputType(e.target.value)}
                        className="border border-gray-300 p-2 rounded-lg w-1/2"
                    >
                        <option value="Input">Input</option>
                        <option value="Value">Value</option>
                    </select>
                </div>


                <div className="inline-flex items-center gap-2 mb-4">
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            id="isPlatinum"
                            type="checkbox"
                            checked={isPlatinum}
                            onChange={(event) => setIsPlatinum(event.target.checked)}
                            value=""
                            className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 hover:bg-gray-300 peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#f230aa] hover:peer-checked:bg-[#f230aa]"></div>
                    </label>
                    <label
                        htmlFor="switch-component-ripple-off"
                        className="text-slate-600 text-md cursor-pointer"
                    >
                        Platinum plan?
                    </label>
                </div>


                {!loading && (
                <>    
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg shadow btnx h hover:text-[#f230aa]"
                >
                    Submit
                </button>
                </>
                )}
            </form>
            
            {loading && (
                <div className="mt-4 p-4 bg-yellow-100 text-yellow-700 rounded-lg">
                    <h3 className="text-lg font-semibold">Loading...</h3>
                </div>
            )}

            {response && (
                <div className="mt-4 p-4 bg-green-100 text-green-700 rounded-lg">
                    <h3 className="text-lg font-semibold">Video created successfully!</h3>
                    <Link to={`/dashboard/videolist`} className="text-blue-500 hover:underline">
                        Video list
                    </Link>
                </div>
            )}
            {error && (
                <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
                    <h3 className="text-lg font-semibold">Error:</h3>
                    <pre>{JSON.stringify(error, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default CreateVideoGenerationQueue;