import React, { useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubtitleEditor = ({ subtitles, subtitleUrl, onClose }) => {

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const [editableSubtitles, setEditableSubtitles] = useState(subtitles);
    const [originalSubtitles, setOriginalSubtitles] = useState({});

    const handleEdit = (id) => {
        setOriginalSubtitles((prevOriginals) => ({
            ...prevOriginals,
            [id]: editableSubtitles.find((subtitle) => subtitle.id === id),
        }));
        setEditableSubtitles((prevSubtitles) =>
            prevSubtitles.map((subtitle) => {
                if (subtitle.id === id) {
                    return { ...subtitle, isEditing: true };
                }
                return { ...originalSubtitles[subtitle.id] || subtitle, isEditing: false };
            })
        );
    };

    const handleSave = (id, newText, newStartTime, newEndTime) => {
        setEditableSubtitles((prevSubtitles) =>
            prevSubtitles.map((subtitle) =>
                subtitle.id === id
                    ? { ...subtitle, text: newText, startTime: newStartTime, endTime: newEndTime, isEditing: false }
                    : subtitle
            )
        );
        setOriginalSubtitles((prevOriginals) => {
            const { [id]: _, ...rest } = prevOriginals;
            return rest;
        });
        console.log(editableSubtitles);
    };

    const handleCancel = (id) => {
        setEditableSubtitles((prevSubtitles) =>
            prevSubtitles.map((subtitle) =>
                subtitle.id === id ? { ...originalSubtitles[id], isEditing: false } : subtitle
            )
        );
        setOriginalSubtitles((prevOriginals) => {
            const { [id]: _, ...rest } = prevOriginals;
            return rest;
        });
    };

    const handleTimeChange = (e, id, type) => {
        const value = e.target.value;
        const timeFormat = /^\d{2}:\d{2}:\d{2},\d{3}$/;
        if (timeFormat.test(value)) {
            setEditableSubtitles((prevSubtitles) =>
                prevSubtitles.map((subtitle) =>
                    subtitle.id === id ? { ...subtitle, [type]: value } : subtitle
                )
            );
        }
    };

    const [loading, setLoading] = useState(false);
    const exportSRT = async () => {
        setLoading(true);
        const srtText = editableSubtitles.map(subtitle => {
            return `${subtitle.id}\n${subtitle.startTime} --> ${subtitle.endTime}\n${subtitle.text}\n`;
        }).join('\n');

        console.log(srtText);

        const blob = new Blob([srtText], { type: 'text/plain' });
        const file = new File([blob], 'subtitles.srt', { type: 'text/plain' });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('url', subtitleUrl);

        try {
            const response = await fetch(`${backendUrl}/videos/upload-subtitle-to-bunny`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok', response);
            }

            setLoading(false);

            const data = await response.json();
            toast("🦄 Successfully edited subtitles", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.log('Upload successful:', data);
        } catch (error) {
            console.error('Error uploading subtitle:', error);
            toast.error("There was a failure in the subtitles edition", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };


    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center overflow-auto">
            <div className="bg-white p-4 rounded-lg shadow-lg w-3/4 max-h-full overflow-y-auto justify-items-center relative">
                <h2 className="text-2xl font-semibold mb-4">Subtitles</h2>
                <div className="absolute top-4 right-4 flex flex-col space-y-2">
                    <button className="text-gray-400 hover:text-gray-500 px-2 py-1 rounded flex justify-end h-12" onClick={onClose}>
                        <FaTimes className='h-8' />
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-xl hover:scale-105 flex justify-center items-center" onClick={exportSRT} disabled={loading}>
                        {loading ? (
                            <svg className="mr-2 ml-2 size-5 h-5 w-5 animate-spin text-white" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (
                            "Save"
                        )}
                    </button>
                </div>
                {editableSubtitles.map((subtitle) => (
                    <div
                        key={subtitle.id}
                        className="mb-4 p-4 border border-gray-300 rounded-lg transition-transform transform hover:scale-105 w-1/2 flex justify-between items-center cursor-pointer"
                        onClick={() => handleEdit(subtitle.id)}
                    >
                        <div className="flex-grow">
                            <div className='font-semibold text-lg'>{subtitle.id}</div>
                            {subtitle.isEditing ? (
                                <div className="flex items-center mb-2 justify-center">
                                    <input
                                        type="text"
                                        className="w-6/12 border border-gray-300 rounded p-2 mr-2 text-black font-semibold"
                                        defaultValue={subtitle.startTime}
                                        onChange={(e) => handleTimeChange(e, subtitle.id, 'startTime')}
                                    />
                                    <span className="w-1/12 mx-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text font-semibold">→</span>
                                    <input
                                        type="text"
                                        className="w-6/12 border border-gray-300 rounded p-2 text-black font-semibold"
                                        defaultValue={subtitle.endTime}
                                        onChange={(e) => handleTimeChange(e, subtitle.id, 'endTime')}
                                    />
                                </div>
                            ) : (
                                <h3 className="text-lg font-bold bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
                                    {subtitle.startTime} → {subtitle.endTime}
                                </h3>
                            )}
                            {subtitle.isEditing ? (
                                <textarea
                                    className="w-full border border-gray-300 rounded p-2 font-semibold"
                                    defaultValue={subtitle.text}
                                    onChange={(e) => setEditableSubtitles((prevSubtitles) =>
                                        prevSubtitles.map((sub) =>
                                            sub.id === subtitle.id ? { ...sub, text: e.target.value } : sub
                                        )
                                    )}
                                />
                            ) : (
                                <p className='font-semibold'>{subtitle.text}</p>
                            )}
                        </div>
                        {subtitle.isEditing && (
                            <div className="flex flex-col space-y-2">
                                <button
                                    className="ml-4 bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 text-white px-4 py-2 rounded-xl transition-transform transform hover:from-indigo-500 hover:via-purple-500 hover:to-pink-500 hover:scale-105 flex justify-center items-center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSave(subtitle.id, subtitle.text, subtitle.startTime, subtitle.endTime);
                                    }}
                                >
                                    <FaCheck />
                                </button>
                                <button
                                    className="ml-4 bg-red-400 text-white px-4 py-2 rounded-xl transition-transform transform hover:bg-red-600 hover:scale-105"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCancel(subtitle.id);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubtitleEditor;