import React from 'react';
import { useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {

    const location = useLocation();

    localStorage.setItem('redirectTo', location.pathname);

    return children;
};

export default ProtectedRoute;