import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import { AiFillVideoCamera, AiOutlineVideoCamera, AiFillAudio, AiOutlineAudio } from 'react-icons/ai';
import Waveform from './Waveform'; // Asegúrate de importar correctamente el componente Waveform

const AudioWaveEffect = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const draw = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = 'white';

            const centerY = canvas.height / 2;
            const maxRadius = 3;
            const spacing = 8;

            for (let i = 0; i < 3; i++) {
                const x = canvas.width / 2 + (i - 1) * spacing;
                const radius = maxRadius * Math.abs(Math.sin((Date.now() + i * 500) * 0.003));
                
                ctx.beginPath();
                ctx.arc(x, centerY, radius, 0, Math.PI * 2);
                ctx.fill();
            }

            animationFrameId = requestAnimationFrame(draw);
        };

        draw();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return <canvas ref={canvasRef} width={24} height={12} />;
};

const VideoSection = ({
    videoCombinadoUrl,
    videoRef,
    isMuted,
    efectoCallEnd,
    audioRef,
    audioCombinadoUrl,
    configCameraDone,
    webcamRef,
    camaraFrontalTracera,
    selectedDevice,
    isMicrophoneActive,
    toggleMicrophone,
    isSpeaking,
    setIsSpeaking,
    silentSeconds,
    setSilentSeconds,
    audioStarted,
    setAudioStarted,
    setIsMicrophoneActive,
    waveformRef,
    respuestaFinal,
    handleNextQuestion,
    loop,
    grabando,
    subido,
    intentosDeSubida,
    Call1,
    Call2,
    selectedMicrophone,
    setSelectedMicrophone,
    countdown,
    setCountdown,
    Call3,
    Call4
}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [progress, setProgress] = useState(0);
    const [internalTime, setInternalTime] = useState(0);

    useEffect(() => {
        let interval;
        if (silentSeconds === 0) {
            setProgress(0);
            setInternalTime(0);
        } else if (silentSeconds > 0 && silentSeconds < 4) {
            const targetProgress = (silentSeconds / 4) * 100;
            interval = setInterval(() => {
                setInternalTime((prev) => {
                    const newTime = prev + 0.1;
                    const newProgress = (newTime / 4) * 100;
                    setProgress(newProgress);
                    if (newProgress >= targetProgress) {
                        clearInterval(interval);
                    }
                    return newTime;
                });
            }, 100); // Ajusta el intervalo según sea necesario

            // console.log("progress", progress); 
            // console.log("silentSeconds", silentSeconds);
        }
        return () => clearInterval(interval);
    }, [silentSeconds, progress]);

    useEffect(() => {
        if (silentSeconds === 4) {
            setProgress(100);
        }
    }, [silentSeconds]);

    const [isIphone, setIsIphone] = useState(false);
    const [iphoneModel, setIphoneModel] = useState('');

    useEffect(() => {
        const detectIphone = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setIsIphone(true);
                if (window.screen.height === 844 && window.screen.width === 390) {
                    setIphoneModel('iPhone 12');
                } else if (window.screen.height === 812 && window.screen.width === 375) {
                    setIphoneModel('iPhone X/XS/11 Pro');
                } else if (window.screen.height === 896 && window.screen.width === 414) {
                    setIphoneModel('iPhone XR/11/11 Pro Max');
                } else {
                    setIphoneModel('Otro modelo de iPhone');
                }
            }
        };

        detectIphone();
    }, []);

    const marginTopClass = isIphone && window.innerHeight > 520 ? 'mt-[-5rem]' : '';


const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 30;
const ALERT_THRESHOLD = 10;

const COLOR_CODES = {
  info: {
    color: "green"
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD
  }
};


  const [remainingPathColor, setRemainingPathColor] = useState(COLOR_CODES.info.color);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [countdown]);

  useEffect(() => {
    if (countdown <= ALERT_THRESHOLD) {
      setRemainingPathColor(COLOR_CODES.alert.color);
    } else if (countdown <= WARNING_THRESHOLD) {
      setRemainingPathColor(COLOR_CODES.warning.color);
    } else {
      setRemainingPathColor(COLOR_CODES.info.color);
    }
  }, [countdown]);

  const calculateTimeFraction = () => {
    const rawTimeFraction = countdown / 60;
    return rawTimeFraction - (1 / 60) * (1 - rawTimeFraction);
  };

  const setCircleDasharray = () => {
    const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    return circleDasharray;
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
      if (countdown < 50) {
          setIsButtonDisabled(false);
      } else {
          setIsButtonDisabled(true);
      }
  }, [countdown]);

  const handleSkipClick = () => {
      setSilentSeconds(4);
  };
    
    return (
        <div className={`flex flex-col items-center justify-center bg-fondo h-full`} style={{ height: '-webkit-fill-available' }}>
            {/* Componente video jhonny */}
            <div className={`z-10 overflow-hidden flex items-center justify-center min-w-[15vh] max-h-60 rounded-full mt-4 md:mt-4 ${marginTopClass}`}>
                {/* video loop jhonny */}
                <div className="relative w-full flex justify-center items-center">
                    <div className={`${isIphone ? 'ultralg:h-28 ultralg:w-28' : ''} w-36 h-36 md:w-48 md:h-48 lg:w-52 lg:h-52 rounded-full overflow-hidden`}>
                        {videoCombinadoUrl ? (
                            <>
                                <video
                                    key="loop"
                                    ref={videoRef}
                                    src={videoCombinadoUrl}
                                    type="video/mp4"
                                    playsInline
                                    muted={isMuted}
                                    disableRemotePlayback
                                    className="w-full h-full object-cover"
                                    onLoadedMetadata={() => {
                                        console.log("video");
                                    }}
                                    style={{ display: efectoCallEnd === false ? 'block' : 'none' }}
                                />
                                <audio ref={audioRef} className='hidden'>
                                    <source src={audioCombinadoUrl} type="audio/mp3" />
                                </audio>
                            </>
                        ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-200">
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="relative w-full flex justify-center items-center mt-4">
                {!respuestaFinal ? (
                    configCameraDone ? (
                        <Webcam
                            ref={webcamRef}
                            className={`${isIphone ? 'ultralg:h-96' : ''} w-100 h-96 md:h-full md:w-10/12 lg:w-7/12 object-cover md:object-contain bg-gray-700 md:bg-inherit`}
                            audio={true}
                            muted={true}
                            videoConstraints={{
                                facingMode: camaraFrontalTracera,
                                deviceId: selectedDevice,
                                width: { max: 9999 },
                                height: { max: 9999 },
                                frameRate: { ideal: 60 },
                            }}
                            audioConstraints={{
                                deviceId: selectedMicrophone
                            }}
                        />
                    ) : (
                        <div className="w-96 h-96 md:h-full md:w-full bg-gray-700 flex items-center justify-center">
                            <p className="text-white">Camera not available</p>
                        </div>
                    )
                ) : null}
            </div>

            <div className="w-full bg-fondo sm:grid sm:grid-cols-3 flex items-center justify-center mt-6 mb-6">
                <div className="hidden sm:flex items-center justify-center col-span-1"></div>
                {!respuestaFinal && (
                    <div className="col-span-2 sm:col-span-1 flex items-center justify-center">
                        {/* <button
                            className={`mr-2 ${!isMicrophoneActive ? 'bg-danger h-10 w-10' : 'bg-good w-10 h-10'} text-white p-3 shadow-lg rounded-md flex items-center justify-center`}
                            onClick={toggleMicrophone}
                        >
                            {!isMicrophoneActive ? (
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                                    <path d="M15 9.4V5C15 3.34315 13.6569 2 12 2C10.8224 2 9.80325 2.67852 9.3122 3.66593M12 19V22M8 22H16M3 3L21 21M5.00043 10C5.00043 10 3.50062 19 12.0401 19C14.51 19 16.1333 18.2471 17.1933 17.1768M19.0317 13C19.2365 11.3477 19 10 19 10M12 15C10.3431 15 9 13.6569 9 12V9L14.1226 14.12C13.5796 14.6637 12.8291 15 12 15Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            ) : (
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                                    <path d="M19 10V12C19 15.866 15.866 19 12 19M5 10V12C5 15.866 8.13401 19 12 19M12 19V22M8 22H16M12 15C10.3431 15 9 13.6569 9 12V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V12C15 13.6569 13.6569 15 12 15Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            )}
                        </button> */}
                        <div className="flex justify-center items-center h-full mr-2">
                        {countdown !== null ? (
                            <div className="relative w-10 h-10">
                                <svg className="absolute top-0 left-0 w-full h-full transform scale-x-[-1]" viewBox="0 0 100 100">
                                    <g className="base-timer__circle">
                                        <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                        <path
                                            id="base-timer-path-remaining"
                                            strokeDasharray={setCircleDasharray()}
                                            className={`base-timer__path-remaining ${remainingPathColor}`}
                                            d="
                                                M 50, 50
                                                m -45, 0
                                                a 45,45 0 1,0 90,0
                                                a 45,45 0 1,0 -90,0
                                            "
                                        ></path>
                                    </g>
                                </svg>
                                <span
                                    id="base-timer-label"
                                    className={`absolute top-0 left-0 w-full h-full flex items-center justify-center text-xl text-white ${
                                        countdown < 30 ? 'animate-pulse' : ''
                                    }`}
                                >
                                    {countdown}
                                </span>
                            </div>
                        ) : (
                            <div className="relative w-10 h-10">
                                <svg className="absolute top-0 left-0 w-full h-full transform scale-x-[-1]" viewBox="0 0 100 100">
                                    <g className="base-timer__circle">
                                        <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                        <path
                                            id="base-timer-path-remaining"
                                            strokeDasharray={setCircleDasharray()}
                                            className={`base-timer__path-remaining ${remainingPathColor}`}
                                            d="
                                                M 50, 50
                                                m -45, 0
                                                a 45,45 0 1,0 90,0
                                                a 45,45 0 1,0 -90,0
                                            "
                                        ></path>
                                    </g>
                                </svg>
                                <span
                                    id="base-timer-label"
                                    className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-xl text-white"
                                >
                                    60
                                </span>
                            </div>
                        )}
                    </div>


                        <div className="flex">
                                <Waveform
                                    ref={waveformRef}
                                    isSpeaking={isSpeaking}
                                    setIsSpeaking={setIsSpeaking}
                                    silentSeconds={silentSeconds}
                                    setSilentSeconds={setSilentSeconds}
                                    audioStarted={audioStarted}
                                    setAudioStarted={setAudioStarted}
                                    setIsMicrophoneActive={setIsMicrophoneActive}
                                    isMicrophoneActive={isMicrophoneActive}
                                    loop={loop}
                                    selectedMicrophone={selectedMicrophone}
                                    efectoCallEnd={efectoCallEnd}
                                />
                            {efectoCallEnd ? (
                                <>
                                <button
                                    className='text-white relative w-40 h-10 rounded-md'
                                    style={{
                                        background: `linear-gradient(to right, rgb(68, 142, 254) 100%, transparent 0%)`
                                    }}
                                >
                                    {Call4} 
                                </button>
                                
                                </>
                            ) : (
                                <button
                                    className='text-white relative w-40 h-10 rounded-md'
                                    style={{
                                        background: `linear-gradient(to right, rgb(68, 142, 254) ${progress}%, transparent 0%)`
                                    }}
                                >
                                    <span className="flex items-center justify-center">
                                        <p className='font-semibold'>
                                        {silentSeconds >= 4 ? '' : silentSeconds > 0 ? Call3 : ''}
                                        </p>
                                        {silentSeconds >= 4 && (
                                            <div className="w-6 h-6 ml-2" style={{ marginTop: 12 }}>
                                                <AudioWaveEffect />
                                            </div>
                                        )}
                                    </span>
                                </button>
                            )}



                        {countdown !== null ? (
                            <button
                                onClick={handleSkipClick}
                                disabled={isButtonDisabled}
                                className={`ml-2 p-2 rounded transition-colors duration-300  ${isButtonDisabled ? 'bg-[#28292B] text-slate-400' : 'bg-blue-500 text-white'}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        ) : (
                            <button
                                disabled
                                className="ml-2 p-2 rounded bg-[#28292B] transition-colors duration-300 text-slate-400"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        )}
                            
                        </div>
                        {/* {grabando ? (
                        <div className='h-8 w-20 bg-green-500 text-white'> grabando</div>
                        ) : (
                        <div className='h-8 w-20 bg-red-500 text-white'> dejo de grabar</div>
                        )} */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoSection;