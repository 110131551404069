import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./context/AuthContext";
import {
  Home,
  VideoAppX,
  Test,
  Mesajes,
  Conferencia,
  VideoAudioSettings,
  VideoRecorder,
  RespuestaList,
  Dashboard,
  CreateVideo,
  VideoApp,
  VideoConference,
  UploadExcel,
  VideoList,
  LoginForm,
  SignUpForm,
  RespuestaDetail,
} from "./pages";
import ProtectedRoute from "./ProtectedRoute";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function App() {
  const [allowedPaths, setAllowedPaths] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const socketUrl = `${backendUrl.replace(/^http/, 'ws')}/ws/video-edit/`;
    console.log('Connecting to WebSocket at:', socketUrl);
    
    const socket = new WebSocket(socketUrl);
    
    socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);
      if (data.status === 'completed') {
        toast(<a href={data.videoUrl} target="_blank" rel="noopener noreferrer">🦄 The video is ready. Click here.</a>, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
      if (!event.wasClean) {
        console.error(`WebSocket closed unexpectedly with code: ${event.code}, reason: ${event.reason}`);
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    // Simula una llamada a la base de datos para obtener los parámetros permitidos
    const fetchAllowedPaths = async () => {
      // Reemplaza esto con tu lógica para obtener los parámetros desde la base de datos
      const paths = await getPathsFromDatabase();
      setAllowedPaths(paths);
      setIsLoading(false);
    };

    fetchAllowedPaths();
  }, []);

  const getPathsFromDatabase = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/videos/urlbases`
      );
      const data = await response.json();
      // Filtrar las rutas que no sean null
      const paths = data.filter((path) => path !== null);
      return paths;
    } catch (error) {
      console.error("Error al obtener las rutas permitidas:", error);
      return [];
    }
  };

  const DynamicRoute = () => {
    const location = useLocation();
    const path = location.pathname.split("/")[1]; // Obtiene el primer segmento de la ruta

    if (isLoading) {
      return (
        <div className="bg-[#1b1b1d] h-screen w-screen flex items-center justify-center text-white"></div>
      ); // Muestra un mensaje de carga mientras se obtienen los datos
    }
    if (allowedPaths.includes(path)) {
      return <VideoApp />;
    }

    return <Navigate to="/" />; // O cualquier otro componente que desees mostrar si la ruta no es válida
  };

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mensajes" element={<Mesajes />} />
            {/* <Route path="/conferencia" element={<Conferencia />} /> */}
            {/* <Route path="/test" element={<Test />} /> */}
            {/* <Route path="/videosettings" element={<VideoAudioSettings />} /> */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/createvideo"
              element={
                <ProtectedRoute>
                  <CreateVideo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/subidafile"
              element={
                <ProtectedRoute>
                  <UploadExcel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/videolist"
              element={
                <ProtectedRoute>
                  <VideoList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/respuestas/:id"
              element={
                <ProtectedRoute>
                  <RespuestaList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/answers/:responseCode"
              element={<RespuestaDetail />}
            />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignUpForm />} />
            <Route path="*" element={<DynamicRoute />} />
          </Routes>
          <ToastContainer />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
